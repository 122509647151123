export const onLogout = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('token_type');
    localStorage.removeItem('email');
    localStorage.removeItem('oldst_accesstoken');
    localStorage.removeItem('phone_number');
    window.location.replace('/');
};
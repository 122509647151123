import docupdate from './../../../assets/logo_1-removebg-preview.png';
import { useNavigate } from 'react-router-dom';

export const Footer = ({ isMobile }: { isMobile: boolean }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        background: '#C5B5F2',
        height: '375px',
        width: '100%',
        padding: '5%',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          paddingBottom: '4rem',
        }}
      >
        <img
          src={docupdate}
          alt="Logo"
          style={{ width: 160, height: 130, padding: 10, cursor: 'pointer' }}
          onClick={() => navigate('/advisory-board-portal-new')}
        />
      </div>
      <div
        style={{
          borderTop: '1px solid #050505',
          width: '100%',
          paddingBottom: '2rem',
        }}
      ></div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          flexDirection: isMobile ? 'column-reverse' : 'row',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            marginTop: isMobile ? '20px' : '0px',
          }}
        >
          © 2023 Docupdate. All rights reserved.
        </span>
        <div
          style={{
            display: 'flex',
            gap: '15px',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              fontWeight: 600,
            }}
            onClick={() =>
              window.open('https://www.docupdate.io/privacy-policy', '_blank')
            }
          >
            Privacy Policy
          </span>
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              fontWeight: 600,
            }}
            onClick={() =>
              window.open('https://www.docupdate.io/tos', '_blank')
            }
          >
            Terms of Service
          </span>
        </div>
      </div>
    </div>
  );
};

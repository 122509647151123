import { Collapse } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

export const FAQ = ({ isMobile }: { isMobile: boolean }) => {
  return (
    <div
      id="faq-space"
      style={{
        display: isMobile ? 'flex' : 'grid',
        gridTemplateColumns: '1fr 1fr',
        margin: '7rem 0rem',
        flexDirection: 'column',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 style={{ fontSize: '3rem' }}>Frequently Asked Questions</h2>
        <span style={{ fontSize: '1.125rem', lineHeight: '1.8' }}>
          Have additional questions? Send us an email at
        </span>
        <a
          href="mailto:support@docupdate.io"
          style={{
            textDecoration: 'underline',
            fontSize: '1.125rem',
            lineHeight: '1.8',
          }}
        >
          support@docupdate.io
        </a>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '3rem',
        }}
      >
        {content.map((item, index) => (
          <Collapse
            accordion
            key={index}
            style={{
              backgroundColor: 'white',
              borderRadius: '0px',
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            <Collapse.Panel
              style={{
                boxShadow: `5px 5px 0px 0px ${item.color}`,
                borderTop: 'none',
                borderRadius: '0px',
              }}
              header={
                <span style={{ fontSize: '1.125rem', fontWeight: 700 }}>
                  {item.title}
                </span>
              }
              key={item.key}
              showArrow={false}
              extra={<PlusOutlined />}
            >
              <p>{item.content}</p>
            </Collapse.Panel>
          </Collapse>
        ))}
      </div>
    </div>
  );
};

const content = [
  {
    key: '1',
    title: 'Why should I use ePrescribe?',
    content:
      'You should use ePrescribe to write prescriptions electronically - even while out of your office or away from your computer. You`ll save time by writing new prescriptions effortlessly and processing renewals with a few taps, eliminating the need to call pharmacies or receive faxes.',
    color: '#e68989',
  },
  {
    key: '2',
    title: 'Is it secure?',
    content:
      'Yes, ePrescribe is password-protected and we’ve applied multiple measures to keep your patient’s information secure. For example, we don’t store any information on your mobile device. In the event that you lose your phone, your patient’s data will remain safe.',
    color: '#94dbdb',
  },
  {
    key: '3',
    title: 'Can I electronically send controlled substances with ePrescribe?',
    content:
      'No, we currently do not allow the prescribing of any controlled substances through the app.',
    color: '#ffc170',
  },
  {
    key: '4',
    title: 'How much does it cost? ',
    content: 'Nothing.',
    color: '#6abce4',
  },
];

import './EPrescribe.scss';
import { ProcessDiv } from './Components/ProcessBox';
import { FAQ } from './Components/FAQ';
import { EPrescribeSignup } from './Components/Signup';
import { Footer } from './Components/Footer';
import { Header } from './Components/Header';
import { useMediaQuery } from 'react-responsive';

const EPrescribe = () => {
  const isMobile = useMediaQuery({ maxWidth: 930 });
  return (
    <div
      style={{
        width: '100%',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '1920px',
        overflowY: 'hidden',
      }}
    >
      <Header isMobile={isMobile} />
      <ProcessDiv isMobile={isMobile} />
      <FAQ isMobile={isMobile} />
      {/* <EPrescribeSignup isMobile={isMobile} /> */}
      <Footer isMobile={isMobile} />
    </div>
  );
};

export default EPrescribe;

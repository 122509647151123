import React, { useEffect, useState } from 'react';
import { Input, Button, message } from 'antd';
import makeApiRequest from '../../api/makeApiRequest';
import { useNavigate } from 'react-router-dom';

const OTPVerificationComponent: React.FC = () => {
  const [otpPhone, setOtpPhone] = useState<string>('');
  const [loadingPhone, setLoadingPhone] = useState<boolean>(false);
  const [phoneVerified, setPhoneVerified] = useState<boolean>(false);
  const email = localStorage.getItem('email');
  const phoneNumber = localStorage.getItem('phone_number');
  const navigate = useNavigate();

  useEffect(() => {
    phoneVerified && navigate('/login-new');
  }, [phoneVerified, navigate])

  const handleNumberOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length <= 6) {
      setOtpPhone(value);
    }
  };

  const validateOTP = (otp: string) => {
    const otpRegex = /^\d{6}$/;
    return otpRegex.test(otp);
}

  const verifyPhoneOtp = async () => {
    setLoadingPhone(true);

    makeApiRequest('/user/verify-phone','POST', {}, {
        userName: email,
        code: otpPhone
    }).then(result => {
      setTimeout(() => {
        if(result.error) message.error(result.error);
        else {
          message.success(`${result?.message}`);
          setPhoneVerified(true)
        };
        setLoadingPhone(false);
        setOtpPhone('');
      }, 1000);
    }).catch(error => { message.warning(error)});
  };

  const resendPhoneOtp = async () => {
    makeApiRequest('/user/resend-phone-verification','POST', {}, {
        userName: email,
        phoneNumber: phoneNumber
    })
    setTimeout(() => {
      message.info('OTP has been resent. Please check your Phone.');
    }, 1000);
  };

  return (
    <div className='main-body-form-container'>
      <div className='main-body-form'>
       <div className='otp-verification'>

        <h2>Phone Number Verification</h2>
        <Input
            size="large"
            placeholder="Enter OTP for Phone Number"
            maxLength={6}
            value={otpPhone}
            onChange={handleNumberOtpChange}
            onPressEnter={()=> validateOTP(otpPhone) && verifyPhoneOtp()}
        />
        <Button
            disabled={!validateOTP(otpPhone)}
            type="primary"
            loading={loadingPhone}
            onClick={verifyPhoneOtp}
            style={{ margin: '10px 5px' }}
        >
            Verify Phone Number
        </Button>
        <p className='resend-otp' onClick={resendPhoneOtp}>{`Resend OTP to Phone number`}</p>
      </div>
     </div>
    </div>
  );
};

export default OTPVerificationComponent;
import './advisoryboard.scss';
import PurpleCircle from '../../assets/purple-circle-p-1080.png';
import Mobile from '../../assets/mobile.png';

const AdvisoryBoard = () => {
    const gradientStyle = {
        padding: '20px',
    };

    return (
        <div className='main-body-form-container' style={gradientStyle}>
         <div className='main-body-form' style={gradientStyle}>
               <> <div className='form-wrapper'>
                    <div className='left left-heading'>
                    <h1 className="heading-3">Paid opportunities <span className="purple-text-span">delivered to your phone</span></h1>
                    <p className="paragraph-large">Earn extra income within 2 minutes. Opportunities for short term and long term engagements with the leading innovators in healthtech, pharma,  and medical devices.<br/></p>
                    </div>
                    <div className='right' >
                        <div className="image-container">
                        <img src={PurpleCircle} className="background-image" alt="Background"/>
                        <img src={Mobile} className="floating-image" alt="Floating"/>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 100 }}>
                   <div className="custom-block" style={{boxShadow: '5px 5px 0px 0px #e68989'}}>
                      <div className="number">1</div>
                      <div className="label">Sign Up</div>
                    </div>
                    <div className="custom-block" style={{boxShadow: '5px 5px 0px 0px #ffc170'}}>
                      <div className="number">2</div>
                      <div className="label">Receive personalized opportunities</div>
                    </div>
                    <div className="custom-block" style={{boxShadow: '5px 5px 0px 0px #5ab5e2'}}>
                      <div className="number">3</div>
                      <div className="label">Get paid</div>
                    </div>
                </div> 
            </>
        </div>
        </div>
    );
};

export default AdvisoryBoard;

import './signin.scss';
import Button from '../../components/Button/button';
import { useEffect, useState } from 'react';
import makeApiRequest from '../../api/makeApiRequest';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import TextBox from '../../components/TextBox/Textbox';
import docUpdateImage from '../../assets/white-logo.png';
import Prompt from '../../components/Prompt/prompt';
import VerifyOTP from '../Signup/verifyOTP';
import { useNavigate } from 'react-router-dom';
import { Switch, Form } from 'antd';
import { useForm } from '../../utils/hooks/useForm';
import { FormItem } from '../../components/FormItem/FormItem';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { useEncryption } from '../../utils/hooks/useEncryption';
import { emailRegex, passwordRegex } from '../../utils/content/regex';

interface Singin {
  email: string;
  password: string;
  phoneNumber: string;
}

const SignIn = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openVerificationForm, setOpenVerificationForm] = useState(false);
  const [modalText, setModalText] = useState('');
  const [formValues, setFormValues] = useState<Singin>({
    email: '',
    password: '',
    phoneNumber: '',
  });
  const [rememberMe, setRememberMe] = useState(false);
  const { form, resetForm, setFieldValue } = useForm();
  const { encryptData, decryptData } = useEncryption();

  useEffect(() => {
    const email = localStorage.getItem('r-u');
    const password = localStorage.getItem('r-p');
    if (email && password) {
      setFieldValue('email', email);
      setFieldValue('password', decryptData(password));
      setRememberMe(true);
    }
  }, []);

  const resendPhoneOtp = async (ph: string) => {
    makeApiRequest(
      '/user/resend-phone-verification',
      'POST',
      {},
      {
        userName: form.getFieldValue('email'),
        phoneNumber: ph,
      },
    );
  };

  const submitLogin = (username: string, password: string) => {
    if (rememberMe) {
      localStorage.setItem('r-u', username);
      localStorage.setItem('r-p', encryptData(password));
    } else {
      localStorage.removeItem('r-u');
      localStorage.removeItem('r-p');
    }
  };

  const onSubmit = async (values: { email: string; password: string }) => {
    setLoading(true);
    const loginInfo = await makeApiRequest(
      '/user/login',
      'POST',
      {},
      { ...values },
    );

    if (loginInfo?.response?.data?.error) {
      setModalText(loginInfo.response.data?.error);
      setLoading(false);
    } else if (loginInfo.phoneNumber) {
      resendPhoneOtp(loginInfo.phoneNumber);
      localStorage.setItem('phone_number', loginInfo.phoneNumber);
      localStorage.setItem('email', values.email);

      setFormValues({
        phoneNumber: loginInfo.phoneNumber,
        email: values.email,
        password: values.password,
      });
      setOpenVerificationForm(true);
      setLoading(false);
    } else if (loginInfo?.data?.AuthenticationResult) {
      const authInfo = { ...loginInfo?.data?.AuthenticationResult };
      localStorage.setItem('email', values.email);
      localStorage.setItem('id_token', authInfo?.IdToken);
      localStorage.setItem(
        'expires_in',
        (new Date().getTime() + authInfo?.ExpiresIn * 1000).toString(),
      );
      localStorage.setItem('access_token', authInfo?.AccessToken);
      localStorage.setItem('refresh_token', authInfo?.RefreshToken);
      localStorage.setItem('token_type', authInfo?.TokenType);
      submitLogin(values.email, values.password);

      setTimeout(() => {
        setLoading(false);
        window.location.replace('/');
      }, 2000);
    } else {
      const error = loginInfo?.error ?? 'Server Error. Please try again later.';
      setLoading(false);
      setModalText(error);
    }
  };

  const gradientStyle = {
    padding: `20px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div className="main-body-form-container" style={gradientStyle}>
      <div
        style={{
          padding: 30,
          backgroundColor: '#7F5CE1',
          borderRadius: 20,
          maxWidth: 450,
          minWidth: 300,
          width: '80%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <img src={docUpdateImage} alt="docupdate" width={235} height={80} />
        </div>
        <Form form={form} onFinish={onSubmit}>
          <FormItem
            name="email"
            additionalRules={[
              {
                validator: (form: RuleObject, value: StoreValue) => {
                  if (emailRegex.test(value)) return Promise.resolve();
                  return Promise.reject(
                    new Error('Please enter a valid email address'),
                  );
                },
              },
            ]}
          >
            <TextBox placeholder="Email" onChange={(value) => {}} />
          </FormItem>
          <FormItem
            name="password"
            additionalRules={[
              {
                validator: (form: RuleObject, value: StoreValue) => {
                  if (passwordRegex.test(value)) return Promise.resolve();
                  return Promise.reject(
                    new Error('Please enter a valid password'),
                  );
                },
              },
            ]}
          >
            <PasswordInput
              onEnter={form.submit}
              placeholder="Password"
              onChange={(value) => {}}
            />
          </FormItem>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '25px 0px 15px 0px',
            }}
          >
            <Button
              label="Forgot Password"
              type="button"
              style={{
                fontSize: '16px',
                height: '35px',
                padding: '0 10px',
                backgroundColor: '#d0c1fc',
                color: '#805ae3',
              }}
              variant={'outline'}
              size="sm"
              handleClick={() => navigate('/forgot-password')}
            />
            <div style={{ color: 'white', display: 'flex', gap: '10px' }}>
              <span>Remember</span>
              <Switch
                value={rememberMe}
                onChange={setRememberMe}
                title="Remember"
              />
            </div>
          </div>
          <Button
            label="Login"
            type="button"
            isLoading={loading}
            className="login-button"
            style={{ width: '175px', fontSize: '18px' }}
            variant={'default'}
            handleClick={form.submit}
            size="sm"
          />
          <p
            style={{
              color: 'white',
              textAlign: 'center',
              marginTop: 15,
              width: '100%',
            }}
          >
            Need an account?
          </p>
          <Button
            label="Sign Up"
            type="button"
            style={{
              width: '125px',
              fontSize: '16px',
              borderColor: 'white',
              height: '35px',
            }}
            variant={'outline'}
            size="sm"
            handleClick={() => navigate('/sign-up-new')}
          />
        </Form>
      </div>
      {openVerificationForm && (
        <VerifyOTP
          userName={formValues.email}
          password={formValues.password}
          phoneNumber={formValues.phoneNumber}
          onClose={() => setOpenVerificationForm(false)}
        />
      )}
      {modalText && (
        <Prompt
          promptTitle={'Login Failed'}
          message={modalText}
          type="warning"
          close={() => setModalText('')}
        />
      )}
    </div>
  );
};

export default SignIn;

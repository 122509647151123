// @flow
import React from 'react';
import { Input } from 'antd';
import './text-box.scss';

const { TextArea } = Input;

type Props = {
  className?: string;
  multiLine?: boolean;
  maskInput?: boolean;
  rows?: number;
  defaultValue?: string;
  value?: string;
  label?: string;
  placeholder?: string;
  note?: React.ReactNode;
  onChange: (val?: any) => void;
  inputStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  error?: boolean;
  mask?: string;
  errorDescription?: string;
  maxLength?: number;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
};

export default function TextBox({
  className = '',
  style,
  multiLine = false,
  maskInput = false,
  rows,
  defaultValue,
  value,
  label,
  placeholder,
  note,
  onChange,
  inputStyle,
  error,
  mask,
  errorDescription,
  maxLength,
  suffix,
  prefix,
}: Props) {
  return (
    <div className={`text-box ${className}`} style={style}>
      {label !== undefined ? <p className="text-box-label">{label}</p> : <></>}
      {multiLine ? (
        <TextArea
          rows={rows}
          defaultValue={defaultValue}
          placeholder={placeholder}
          value={value || undefined}
          onChange={onChange}
        />
      ) : (
        <Input
          defaultValue={defaultValue}
          placeholder={placeholder}
          value={value || undefined}
          onInput={onChange}
          maxLength={maxLength}
          status={error ? 'error' : ''}
          style={{ width: '100%', ...inputStyle, padding: 20 }}
          suffix={suffix}
          prefix={prefix}
        />
      )}
      <span style={{ color: 'red', fontSize: '12px', display: 'flex' }}>
        {error &&
          (value !== ''
            ? `${
                errorDescription !== ''
                  ? errorDescription
                  : `${label} is invalid.`
              }`
            : `${label} is a required field.`)}
      </span>
      {note}
    </div>
  );
}

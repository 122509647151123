import React from 'react';
import { Layout, Menu, Avatar } from 'antd';
import { UserOutlined, BellOutlined } from '@ant-design/icons';
import docupdate from '../../assets/footer.png';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;

const AppHeader = () => {
    const navigate = useNavigate();
  return (
    <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%',  height: '64px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 30px', backgroundColor: '#fff' }}>
      <div className="logo" style={{lineHeight : '0px', cursor: 'pointer'}}>
        <img src={docupdate} alt="Logo" style={{ height: 70, padding: 10 }} onClick={()=> navigate('/advisory-board-portal-new')} />
      </div>
      {/* <div>
        <Menu mode="horizontal">
          <Menu.Item key="notification" icon={<BellOutlined />} />
          <Menu.Item key="profile" icon={<Avatar size="small" icon={<UserOutlined />} />} />
        </Menu>
      </div> */}
    </Header>
  );
};

export default AppHeader;
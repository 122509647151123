// @flow
import './create-user.scss';
import { useEffect, useState } from 'react';
import { Select, Button } from 'antd';
import makeApiRequest from '../../api/makeApiRequest';
import Prompt from '../../components/Prompt/prompt';
import { useNavigate } from 'react-router-dom';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import LabelTextBox from '../../components/LableTextBox/LabelTextbox';
import VerifyOTP from '../Signup/verifyOTP';
import { verifyPhoneNumberFormat } from '../../utils/methods/verifyPhoneNumberFormat';

type RepUserFormProps = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  npiNumber: number;
  phoneNumber: string;
  gender?: string;
  messagingFrequency?: number;
};

const initialFormState: RepUserFormProps = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  npiNumber: 0,
  phoneNumber: '',
};

const AdvisoryPortal = () => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [existingPhoneNumber, setExistingPhoneNumber] = useState('');
  const [modalText, setModalText] = useState('');
  const [openVerificationForm, setOpenVerificationForm] = useState(false);
  const [modalType, setModalType] = useState<
    'warning' | 'success' | 'info' | 'delete'
  >('success');
  const [formValues, setFormValues] =
    useState<RepUserFormProps>(initialFormState);
  const [initialValues, setInitialValues] =
    useState<RepUserFormProps>(initialFormState);

  const navigate = useNavigate();

  const { Option } = Select;
  const email = localStorage.getItem('email');

  const getUserDetail = () => {
    setIsLoading(true);
    makeApiRequest(`/user/details/${email}`, 'GET').then((result) => {
      const userdata = result.data;
      const messagingFrequency = userdata.messagingFrequency
        ? userdata.messagingFrequency.toString()
        : '';
      setInitialValues({
        ...userdata,
        messagingFrequency,
      });
      setFormValues({
        ...userdata,
        messagingFrequency,
      });
      setExistingPhoneNumber(userdata.phoneNumber);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getUserDetail();
  }, [email]);

  const resendOTP = (ph: string) => {
    makeApiRequest(
      '/user/resend-phone-verification',
      'POST',
      {},
      {
        userName: formValues.email,
        phoneNumber: ph,
      },
    );
  };

  const onTextBoxChange = (label: string | number, value: any) => {
    if (label === 'phoneNumber') {
      setFormValues((prevState) => ({
        ...prevState,
        [label]: value,
      }));
    } else if (label === 'gender') {
      setFormValues((prevState) => ({
        ...prevState,
        [label]: value,
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [label]: value.target.value,
      }));
    }
  };

  const checkValidation = () => {
    return (
      formValues.firstName !== '' &&
      formValues.lastName !== '' &&
      formValues.npiNumber !== null &&
      formValues.phoneNumber !== '' &&
      formValues.email !== '' &&
      formValues.messagingFrequency &&
      !Number.isNaN(Number(formValues.messagingFrequency))
    );
  };

  const hasFormUpdated = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialValues);
  };

  const validateNPI = (s: string) => /^[12]\d{9}$/.test(s);

  const onSubmit = async () => {
    if (formValues.phoneNumber === existingPhoneNumber) {
      if (checkValidation()) {
        setIsSaving(true);
        const { ...payload } = formValues;
        await makeApiRequest(
          '/user/details',
          'PUT',
          {},
          {
            ...payload,
            messagingFrequency: Number(formValues.messagingFrequency) || 0,
            phoneNumber: formValues.phoneNumber.replace('+', ''),
            streetAddress: formValues.streetAddress || '',
            city: formValues.city || '',
            state: formValues.state || '',
            zipCode: formValues.zipCode || '',
            gender: formValues.gender || '',
          },
        )
          .then((result) => {
            setIsSaving(false);
            if (result.error) {
              setModalText(String(result.error));
              setModalType('warning');
            } else if (result.message) {
              setModalText(String(result.message));
              setModalType('success');
            } else {
              setModalText('User Info Updated Successfully.');
              setModalType('success');
            }
          })
          .catch((error: any) => {
            setIsSaving(false);
            setModalText('Error while updating user.');
            setModalType('warning');
          });
      } else {
        setShowError(true);
        setIsSaving(false);
      }
    } else {
      setModalText('Please verify the updated phone number.');
      setModalType('warning');
    }
  };

  return (
    <>
      <div
        style={{
          width: '99%',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid black',
          borderRadius: 10,
          backgroundColor: '#faf6f6',
          margin: '10px 0px',
        }}
      >
        <div style={{ padding: 30 }}>
          <h1>Personal Information</h1>
          <p>
            Ensure your profile reflects the latest details by completing the
            form below to update your information.
          </p>
        </div>
        <div className="user-fields">
          <LabelTextBox
            defaultValue={formValues.firstName}
            error={showError && formValues.firstName === ''}
            errorDescription="Firstname required."
            placeholder="First Name"
            value={formValues.firstName}
            onChange={(value) => onTextBoxChange('firstName', value)}
          />
          <LabelTextBox
            defaultValue={formValues.lastName}
            error={showError && formValues.lastName === ''}
            errorDescription="LastName required."
            value={formValues.lastName}
            placeholder="Last Name"
            onChange={(value) => onTextBoxChange('lastName', value)}
          />
          <LabelTextBox
            style={{ color: 'grey' }}
            defaultValue={formValues.email}
            placeholder="Email"
            value={formValues.email}
            isReadonly={true}
            onChange={(value) => onTextBoxChange('email', value)}
          />
          <div style={{ marginTop: '25px' }}>
            <PhoneInput
              inputStyle={{ height: '55px' }}
              country={'us'}
              value={formValues.phoneNumber}
              onChange={(value1, countryData: CountryData) => {
                onTextBoxChange('phoneNumber', value1)
                if (verifyPhoneNumberFormat(value1, countryData, existingPhoneNumber)) {
                  resendOTP(value1);
                  setOpenVerificationForm(true);
                }
              }}

            />
          </div>
          <LabelTextBox
            defaultValue={formValues.npiNumber?.toString()}
            error={showError && !validateNPI(formValues.npiNumber?.toString())}
            errorDescription="NPI must be 10 digit long and start with 1 or 2 and not contain any letters or special characters."
            maxLength={10}
            isReadonly={true}
            value={formValues.npiNumber?.toString()}
            onChange={(value) => onTextBoxChange('npiNumber', value)}
          />
          <LabelTextBox
            defaultValue={formValues.streetAddress}
            placeholder="Street Address"
            value={formValues.streetAddress}
            onChange={(value) => onTextBoxChange('streetAddress', value)}
          />
          <LabelTextBox
            defaultValue={formValues.city}
            placeholder="City"
            value={formValues.city}
            onChange={(value) => onTextBoxChange('city', value)}
          />
          <LabelTextBox
            defaultValue={formValues.state}
            placeholder="State"
            value={formValues.state}
            onChange={(value) => onTextBoxChange('state', value)}
          />
          <LabelTextBox
            defaultValue={formValues.zipCode}
            placeholder="Zip Code"
            value={formValues.zipCode}
            onChange={(value) => onTextBoxChange('zipCode', value)}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <LabelTextBox
              placeholder="Days Between Messages"
              error={showError && !formValues.messagingFrequency}
              defaultValue={formValues.messagingFrequency?.toString()}
              value={formValues.messagingFrequency?.toString()}
              onChange={(value) => onTextBoxChange('messagingFrequency', value)}
              maxLength={3}
            />
            <span style={{ color: 'gray', fontSize: '12px' }}>
              How often would you like to receive messages from us
            </span>
          </div>
          <Select
            style={{ width: '100%', marginTop: 25 }}
            placeholder="Gender"
            value={formValues.gender}
            optionFilterProp="children"
            onChange={(val) => onTextBoxChange('gender', val)}
          >
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>
          </Select>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            padding: 30,
            width: '100%',
          }}
        >
          <Button
            style={{
              width: 300,
              backgroundColor: `${
                checkValidation() && hasFormUpdated()
                  ? '#7e5ae2'
                  : 'rgb(199 196 207)'
              }`,
              color: 'white',
              height: 45,
              fontSize: 18,
              fontWeight: 600,
            }}
            className={`${
              !checkValidation() || !hasFormUpdated()
                ? 'blue-button-disabled'
                : 'login-button'
            }`}
            disabled={isLoading || !checkValidation() || !hasFormUpdated()}
            loading={isSaving}
            onClick={() => onSubmit()}
          >
            {isSaving ? 'Saving...' : 'Update User Info'}
          </Button>
        </div>
      </div>
      {openVerificationForm && (
        <VerifyOTP
          userName={formValues.email}
          phoneNumber={formValues.phoneNumber}
          onClose={() => setOpenVerificationForm(false)}
          getUserDetail={getUserDetail}
        />
      )}
      {modalText && (
        <Prompt
          promptTitle={modalType}
          message={modalText}
          type={modalType}
          close={() => {
            setModalText('');
            modalType === 'success' && navigate('/');
            getUserDetail();
          }}
        />
      )}
    </>
  );
};

export default AdvisoryPortal;

import docupdate from './../../../assets/logo_1-removebg-preview.png';
import { useNavigate } from 'react-router-dom';

// interface ScrollProps {
//   behavior: 'smooth';
//   block: 'nearest';
//   inline: 'start';
// }

// const SCROLL_PROPS: ScrollProps = {
//   behavior: 'smooth',
//   block: 'nearest',
//   inline: 'start',
// };

export const Header = ({ isMobile }: { isMobile: boolean }) => {
  const navigate = useNavigate();
  // const signupElement = document.getElementById('signup-space');
  // const processElement = document.getElementById('process-space');
  // const faqElement = document.getElementById('faq-space');

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: isMobile ? 'start' : 'center',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <img
          src={docupdate}
          alt="Logo"
          style={{ width: 96, height: 78, padding: 10, cursor: 'pointer' }}
          onClick={() => navigate('/advisory-board-portal-new')}
        />
        {/* <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'space-between',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <span
            className="header-links"
            //onClick={() => processElement?.scrollIntoView(SCROLL_PROPS)}
          >
            Process
          </span>
          <span
            className="header-links"
            //onClick={() => faqElement?.scrollIntoView(SCROLL_PROPS)}
          >
            FAQ
          </span>
          <span
            className="header-links"
            //onClick={() => signupElement?.scrollIntoView(SCROLL_PROPS)}
          >
            Contact
          </span>
        </div> */}
      </div>
      <div className="main-heading">
        <h1 style={{ fontSize: '3rem' }}>The Facts About ePrescribe</h1>
      </div>
    </>
  );
};

export const config = {
    apiUrl: process.env.REACT_APP_API_HOST,
    clientCredentialInternal: process.env.REACT_APP_CLIENT_CREDENTIALS_INTERNAL,
    secretKey: process.env.REACT_APP_API_SECRET_KEY,
    clientCredentialMobile: process.env.REACT_APP_CLIENT_CREDENTIALS_MOBILE
};

export const grantType = {
    CLIENT_CREDENTIALS: 'na'
};

export const storageKey = {
    accessToken: 'oldst_accesstoken',
    sidebarState: 'sidebarState'
};

import { createPortal } from 'react-dom';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Input, Button, message } from 'antd';
import makeApiRequest from '../../api/makeApiRequest';
import { useNavigate } from 'react-router-dom';
import './verify-otp.scss';

interface VerifyOTPProps {
    onClose: () => void,
    userName: string,
    password?: string,
    phoneNumber: string,
  getUserDetail?: ()=> void
}

const VerifyOTP = ({ userName, password, phoneNumber, onClose, getUserDetail }: VerifyOTPProps) => {
    const [otpPhone, setOtpPhone] = useState<string>('');
    const [loadingPhone, setLoadingPhone] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleNumberOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (value.length <= 6) {
        setOtpPhone(value);
      }
    };

    const validateOTP = (otp: string) => {
      const otpRegex = /^\d{6}$/;
      return otpRegex.test(otp);
  }

    const verifyPhoneOtp = async () => {
      setLoadingPhone(true);

      makeApiRequest('/user/verify-phone','POST', {}, {
          userName: userName,
          code: otpPhone,
          phoneNumber: phoneNumber
      }).then(result => {
        setTimeout(() => {
          if(result.error) message.error(result.error);
          else {
            message.success(`${result?.message}`);
            getUserDetail && getUserDetail()
            password && login();
            onClose();
          };
          setLoadingPhone(false);
          setOtpPhone('');
        }, 1000);
      }).catch(error => { message.warning(error)});
    };

    const resendPhoneOtp = async () => {
      makeApiRequest('/user/resend-phone-verification','POST', {}, {
          userName: userName,
          phoneNumber: phoneNumber
      })
      setTimeout(() => {
        message.info('OTP has been resent. Please check your Phone.');
      }, 1000);
    };

    const login = async () => {
        if(userName && password) {
            const loginInfo = await makeApiRequest('/user/login',
            'POST',
            {},
            {email: userName, password: password}
            );
            if(loginInfo?.data?.AuthenticationResult){
                const authInfo = {...loginInfo?.data?.AuthenticationResult};
                localStorage.setItem('email', userName);
                localStorage.setItem('id_token', authInfo?.IdToken);
                localStorage.setItem('expires_in', (new Date().getTime() + authInfo?.ExpiresIn * 1000).toString());
                localStorage.setItem('access_token', authInfo?.AccessToken);
                localStorage.setItem('refresh_token', authInfo?.RefreshToken);
                localStorage.setItem('token_type', authInfo?.TokenType);
                navigate('/');
            }
        }
    }

    return (
        <React.Fragment>
            {
                createPortal(
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ type: 'spring', bounce: 0, duration: 0.40 }}
                        exit={{ opacity: 0 }}
                        className='links-form-wrapper'>
                        <motion.div
                            initial={{ opacity: 0, y: 0 }}
                            animate={{ opacity: 1, y: 100 }}
                            transition={{ type: 'spring', bounce: 0, duration: 0.40 }}
                            className='links-form-container'>
                              <div className='otp-verification'>
                                <h2>Phone Number Verification</h2>
                                <Input
                                    size="large"
                                    placeholder="Enter OTP for Phone Number"
                                    maxLength={6}
                                    value={otpPhone}
                                    onChange={handleNumberOtpChange}
                                    onPressEnter={()=> validateOTP(otpPhone) && verifyPhoneOtp()}
                                />
                                <Button
                                    disabled={!validateOTP(otpPhone)}
                                    type="primary"
                                    loading={loadingPhone}
                                    onClick={verifyPhoneOtp}
                                    style={{ margin: '10px 5px' }}
                                >
                                    Verify Phone Number
                                </Button>
                                <p className='resend-otp' onClick={resendPhoneOtp}>{`Resend OTP to Phone number`}</p>
                                </div>

                            <div className='links-form-footer'>
                        <Button onClick={() => {
                          onClose();
                          getUserDetail && getUserDetail();
                        }}>Close</Button>
                            </div>

                        </motion.div>
                    </motion.div>
                    , document.body)
            }
        </React.Fragment>
    );
};

export default VerifyOTP;

import './forgotPassword.scss';
import Button from '../../components/Button/button';
import { useState } from 'react';
import makeApiRequest from '../../api/makeApiRequest';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import TextBox from '../../components/TextBox/Textbox';
import docUpdateImage from '../../assets/white-logo.png';
import Prompt from '../../components/Prompt/prompt';
import { useNavigate } from 'react-router-dom';
import Form from 'antd/es/form/Form';
import { useForm } from '../../utils/hooks/useForm';
import { FormItem } from '../../components/FormItem/FormItem';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { useCountDown } from '../../utils/hooks/useCountDown';
import { emailRegex, passwordRegex } from '../../utils/content/regex';

interface IModelText {
  type: 'warning' | 'success' | 'info' | 'delete';
  message: string;
  action?: () => void;
}

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [modalText, setModalText] = useState<IModelText | undefined>(undefined);
  const [verifiedEmail, setEmailVerified] = useState('');
  const { form, resetForm } = useForm();
  const navigate = useNavigate();
  const { count, resetCount } = useCountDown(60);

  const onSubmit = async (values: any) => {
    setLoading(true);
    if (verifiedEmail) {
      const updatePassword = await makeApiRequest(
        '/user/confirm-forgot-password',
        'POST',
        {},
        {
          email: verifiedEmail,
          newPassword: values.password,
          verificationCode: values.verificationCode,
        },
      );
      if (updatePassword.error) {
        setModalText({ type: 'warning', message: updatePassword.error });
      } else {
        setModalText({
          type: 'success',
          message: updatePassword.message,
          action: () => navigate('/login-new'),
        });
        resetForm();
      }
      return setLoading(false);
    }
    const verifyEmail = await makeApiRequest(
      '/user/forgot-password',
      'POST',
      {},
      values,
    );
    if (verifyEmail.error) {
      setModalText({ type: 'warning', message: 'Not a valid email address.' });
    } else {
      setModalText({ type: 'success', message: verifyEmail.message });
      setEmailVerified(values.email);
      resetCount();
      resetForm();
    }
    setLoading(false);
  };

  const resendCode = async () => {
    setLoadingResend(true);
    const resend = await makeApiRequest(
      '/user/forgot-password',
      'POST',
      {},
      { email: verifiedEmail },
    );
    if (resend.error) {
      setModalText({ type: 'warning', message: resend.error });
    } else {
      setModalText({ type: 'success', message: resend.message });
      resetCount();
    }
    setLoadingResend(false);
  };

  const gradientStyle = {
    padding: `20px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div className="main-body-form-container" style={gradientStyle}>
      <div
        style={{
          padding: 30,
          backgroundColor: '#7F5CE1',
          borderRadius: 20,
          maxWidth: 450,
          minWidth: 300,
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img src={docUpdateImage} alt="docupdate" width={235} height={80} />
        </div>
        <Form form={form} onFinish={onSubmit}>
          {verifiedEmail ? (
            <>
              <FormItem
                name="password"
                additionalRules={[
                  {
                    validator: (form: RuleObject, value: StoreValue) => {
                      if (passwordRegex.test(value)) return Promise.resolve();
                      return Promise.reject(
                        new Error(
                          'Password must be at least 8 characters long and contain a lowercase letter, an uppercase letter, a number, and a special character.',
                        ),
                      );
                    },
                  },
                ]}
              >
                <PasswordInput
                  placeholder="New Password"
                  onChange={(value: any) => {}}
                />
              </FormItem>
              <FormItem
                name="newPassword"
                additionalRules={[
                  {
                    validator: (ruleObject: RuleObject, value: StoreValue) => {
                      const newPassword = form.getFieldValue('password');
                      if (newPassword === value) return Promise.resolve();
                      return Promise.reject(
                        new Error(
                          'New password does not match with confirm password',
                        ),
                      );
                    },
                  },
                ]}
              >
                <PasswordInput
                  placeholder="Confirm Password"
                  onChange={(value: any) => {}}
                />
              </FormItem>
              <FormItem name="verificationCode">
                <TextBox
                  placeholder="Verification Code"
                  onChange={(value: any) => {}}
                />
              </FormItem>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                {count ? (
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: '16px',
                      color: 'white',
                      padding: '0px 10px',
                      marginTop: '10px',
                    }}
                  >
                    Resend Code in {count}
                  </span>
                ) : (
                  <Button
                    label={'Resend Code'}
                    type="button"
                    isLoading={loadingResend}
                    className="login-button"
                    style={{ width: '175px', fontSize: '18px' }}
                    variant={'default'}
                    handleClick={resendCode}
                    size="sm"
                  />
                )}
                <Button
                  label={'Submit'}
                  type="button"
                  isLoading={loading}
                  className="login-button"
                  style={{ width: '175px', fontSize: '18px' }}
                  variant={'default'}
                  handleClick={form.submit}
                  size="sm"
                />
              </div>
            </>
          ) : (
            <>
              <FormItem
                name="email"
                additionalRules={[
                  {
                    validator: (form: RuleObject, value: StoreValue) => {
                      if (emailRegex.test(value)) return Promise.resolve();
                      return Promise.reject(
                        new Error('Please enter a valid email address'),
                      );
                    },
                  },
                ]}
              >
                <TextBox placeholder="Email" onChange={(value: any) => {}} />
              </FormItem>
              <Button
                label={'Send Code'}
                type="button"
                isLoading={loading}
                className="login-button"
                style={{ width: '175px', fontSize: '18px' }}
                variant={'default'}
                handleClick={form.submit}
                size="sm"
              />
            </>
          )}
        </Form>
      </div>
      {modalText && (
        <Prompt
          promptTitle={modalText.type}
          message={modalText.message}
          type={modalText.type}
          close={() => {
            setModalText(undefined);
            modalText.action && modalText.action();
          }}
        />
      )}
    </div>
  );
};

export default ForgotPassword;

import React from 'react';
import './dnc.scss';

const DNC: React.FC = () => {
  return (
    <div className='text-block' style={{ padding: '20px' }}>
      <h1 className='big-heading'>Do Not Call Policy</h1>
     
<p>Consumers may request, by telephone or in writing, that our company stop calling them and place their names on a "Do Not Call" list. Although we may ask for your name, address and telephone number, we will accept your request even if you only provide your telephone number. You have a right to have calls stopped and to be put on our "Do Not Call" list even if you are still a customer.</p>
<p>Requests can be sent to: Support@docupdate.io</p>
<p>‍When we receive your request, your telephone number is added to our "Do Not Call" list within five business days. Further, it will take up to a total of 10 days from the receipt of your request to completely remove a phone number from all our calling lists. Your request will stay on our list for at least ten years. If you move, change your telephone number, or add an additional telephone number, you must provide us with the new telephone number in order for us to prevent calls to that number. We will not share the information you provide with anyone except affiliated companies or subsidiaries without your prior written permission.</p>
<p>All employees that engage in outbound telephone solicitation are trained on this policy and made aware of these procedures before they are allowed to place calls to consumers. Managers, supervisors, or trainers review the policy with these employees periodically.</p>
<p>If the consumer notifies us that he/she has received a telephone call from us after that consumer has already requested to be placed on our "Do Not Call" list, we will apologize for the intrusion and have a manager or supervisor investigate. The manager or supervisor will also personally confirm that the telephone number is on the "Do Not Call" list. Upon request, we will mail the consumer a confirmation that the telephone number is on the list.</p>
<p>The Federal Trade Commission offers a free service to consumers that allows them to place their telephone numbers on a National Do Not Call Registry. This service will reduce the number of telemarketing calls received. Consumers may add their telephone numbers to the list either by calling 888-382-1222 or through the Internet at www.donotcall.gov. It may take several weeks before you notice a reduction in calls.  </p>
</div>
  );
};

export default DNC;
import { Form } from 'antd';
import { ReactNode } from 'react';

interface FormItemProps {
  children: ReactNode,
  name: string;
  fieldName?: string;
  isRequired?: boolean;
  additionalRules?: any[];
}

export const FormItem: React.FC<FormItemProps> = ({
  name,
  children,
  fieldName,
  isRequired = true,
  additionalRules = [],
}) => (
  <Form.Item
    style={{ marginBottom: 0 }}
    name={name}
    rules={[
      { required: isRequired, message: `${fieldName ?? 'Field'} is required` },
      ...additionalRules,
    ]}
    validateFirst
  >
    {children}
  </Form.Item>
);

import docupdate from '../../assets/footer.png';
import { useMediaQuery } from 'react-responsive';
import './footer.scss';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const isMobile = useMediaQuery({maxWidth: 768});
  const navigate = useNavigate();

  const linkStyle = {
    marginRight: isMobile ? '0' : '15px',
    marginBottom: isMobile ? '10px' : '0',
    color: '#7e5ae2'
  };

  return (
    <footer className='footer' style={{ background: 'white' }}>
      <div className='logo-footer'>
        <img src={docupdate} width={isMobile ? 125 : 155} height={isMobile ? 50 : 61} alt="Logo" onClick={() => navigate('/')}/>
      </div>
      <div>
      <div className='link-container'>
        <Link to="/terms-of-service-new" style={linkStyle}>Terms of Service</Link>
        <Link to="/privacy-policy-new" style={linkStyle}>Privacy Policy</Link>
        <Link to="/dnc-policy-new" style={linkStyle}>DNC Policy</Link>
        </div>
              {isMobile && <div className='arr'>
        Docupdate. All Rights Reserved, 2024.
        </div>}
      </div>
      {!isMobile && <div className='arr'>
        Docupdate. All Rights Reserved, 2024.
      </div>}
    </footer>
  );
};

export default Footer;
import { useState, useEffect } from 'react';

export const useCountDown = (range: number) => {
  const [count, setCount] = useState(range);

  useEffect(() => {
    if (count === 0) return;
    const timeout = setTimeout(() => {
      setCount((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timeout);
  }, [count]);

  const resetCount = () => setCount(range);

  return { count, resetCount };
}
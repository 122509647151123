import { CountryData } from "react-phone-input-2";

export const verifyPhoneNumberFormat = (phoneNumber: string, countryData: CountryData, currentPhoneNumber: string): boolean => {
  if (!phoneNumber || !countryData || !currentPhoneNumber) return false;

  let isVerified = true;

  if (phoneNumber === currentPhoneNumber) isVerified = false;

  if (countryData.format.split('.').length - 1 !== phoneNumber.length) isVerified = false;

  return isVerified;
}
import { useMediaQuery } from 'react-responsive';
import './signup.scss';
import Button from '../../components/Button/button';
import { Link, useNavigate } from 'react-router-dom';
import { Checkbox } from 'antd';
import { useState } from 'react';
import makeApiRequest from '../../api/makeApiRequest';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import TextBox from '../../components/TextBox/Textbox';
import docUpdateImage from '../../assets/white-logo.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import VerifyOTP from './verifyOTP';
import Prompt from '../../components/Prompt/prompt';
import { emailRegex, passwordRegex } from '../../utils/content/regex';

interface Signup {
  firstName: string;
  lastName: string;
  npiNumber: number | null;
  phoneNumber: string;
  email: string;
  password: string;
}

interface IPrompt {
  type: 'warning' | 'success' | 'info' | 'delete';
  message: string;
}

const SignUp = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [repeatingEmail, setRepeatingEmail] = useState(false);
  const [openVerificationForm, setOpenVerificationForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState<IPrompt | undefined>(
    undefined,
  );
  const [formValues, setFormValues] = useState<Signup>({
    firstName: '',
    lastName: '',
    npiNumber: null,
    phoneNumber: '',
    email: '',
    password: '',
  });
  const navigate = useNavigate();
  const [hasUserConsent, setHasUserConsent] = useState(false);
  const [hasUserAgreedToPolicy, setHasUserAgreedToPolicy] = useState(false);

  const onTextBoxChange = (label: string | number, value: any) => {
    if (label === 'npiNumber') {
      setFormValues((prevState) => ({
        ...prevState,
        [label]: parseInt(value.target.value),
      }));
    } else if (label === 'phoneNumber') {
      setFormValues((prevState) => ({
        ...prevState,
        [label]: value,
      }));
    } else {
      if (label === 'email') setRepeatingEmail(false);
      setFormValues((prevState) => ({
        ...prevState,
        [label]: value.target.value,
      }));
    }
  };

  const checkValidation = () => {
    return (
      hasUserConsent &&
      hasUserAgreedToPolicy &&
      formValues.email !== '' &&
      formValues.firstName.length !== 0 &&
      formValues.lastName.length !== 0 &&
      formValues.npiNumber !== null &&
      formValues.password !== '' &&
      formValues.phoneNumber !== ''
    );
  };

  const onSubmit = async () => {
    setLoading(true);
    if (
      checkValidation() &&
      formValues.npiNumber &&
      validateNPI(formValues.npiNumber.toString()) &&
      validatePassword(formValues.password) &&
      validateEmail(formValues.email)
    ) {
      await makeApiRequest('/user/signup', 'POST', {}, { ...formValues })
        .then((result: any) => {
          if (result?.error) {
            setErrorMessage({ message: result.error, type: 'warning' });
            setLoading(false);
          } else if (result?.message) {
            setErrorMessage({ message: result?.message, type: 'success' });
            setLoading(false);
            localStorage.setItem('email', formValues.email);
            localStorage.setItem('phone_number', formValues.phoneNumber);
            setOpenVerificationForm(true);
          } else {
            localStorage.setItem('email', formValues.email);
            localStorage.setItem('phone_number', formValues.phoneNumber);
            navigate('/verify-otp');
          }
        })
        .catch((err: any) => {
          setLoading(false);
          setErrorMessage({
            message: `${err?.response?.response?.data?.message}`,
            type: 'warning',
          });
        });
    } else {
      setShowError(true);
    }

    setLoading(false);
  };

  const validateNPI = (s: string) =>
    /^[12]\d{9}$/.test(s) && parseInt(s) < 2147483647;

  const validatePassword = (password: string) => {
    if (password.length <= 7) {
      return false;
    }

    return passwordRegex.test(password);
  };

  const validateEmail = (email: string) => {
    return emailRegex.test(email);
  };

  const gradientStyle = {
    padding: `${isMobile ? '0' : '20px'}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div className="main-body-form-container" style={gradientStyle}>
      <div className="main-body-form" style={gradientStyle}>
        {isMobile ? (
          <div style={{ padding: `${isMobile ? '0' : '30px'}` }}>
            <div
              style={{
                padding: 30,
                backgroundColor: '#7F5CE1 ',
                borderRadius: 20,
                width: '100%',
                maxWidth: 450,
                margin: '20px 0px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '20px',
                }}
              >
                <img
                  src={docUpdateImage}
                  alt="docupdate"
                  width={235}
                  height={80}
                />
              </div>
              <TextBox
                defaultValue={formValues.firstName}
                placeholder="First Name"
                onChange={(value) => onTextBoxChange('firstName', value)}
                error={showError && formValues.firstName.length === 0}
                errorDescription="First Name is required field."
              />
              <TextBox
                defaultValue={formValues.lastName}
                placeholder="Last Name"
                onChange={(value) => onTextBoxChange('lastName', value)}
                error={showError && formValues.lastName.length === 0}
                errorDescription="Last Name is required field."
              />
              <TextBox
                defaultValue={formValues.email}
                placeholder="Email"
                onChange={(value) => onTextBoxChange('email', value)}
              />
              {repeatingEmail && (
                <p style={{ color: 'red', maxWidth: 450, textAlign: 'left' }}>
                  A user with the given email already exists.
                </p>
              )}
              <TextBox
                style={{ marginBottom: '25px' }}
                defaultValue={formValues.npiNumber?.toString()}
                error={
                  showError &&
                  !(
                    formValues.npiNumber &&
                    validateNPI(formValues.npiNumber?.toString())
                  )
                }
                errorDescription="NPI must be 10 digit long and start with 1 or 2 less than 2147483647 and not contain any letters or special characters."
                placeholder="NPI Number"
                maxLength={10}
                onChange={(value) => onTextBoxChange('npiNumber', value)}
              />
              <PhoneInput
                country={'us'}
                value={formValues.phoneNumber}
                onChange={(value) => onTextBoxChange('phoneNumber', value)}
              />
              <PasswordInput
                style={{ marginBottom: '15px' }}
                error={showError && !validatePassword(formValues.password)}
                errorDescription="Password must be atleast 8 letter with one digit, one special character, one capital letter, one small letter."
                placeholder="Password"
                onChange={(value) => onTextBoxChange('password', value)}
              />
              <div
                style={{
                  color: 'white',
                  fontSize: 12,
                  display: 'flex',
                  alignItems: 'start',
                }}
              >
                <Checkbox
                  style={{ marginBottom: 0, marginTop: 2 }}
                  defaultChecked={hasUserConsent}
                  onChange={(val) => setHasUserConsent(val.target.checked)}
                />
                <p
                  style={{
                    alignItems: 'flex-start',
                    margin: '0px 0px 0px 10px',
                  }}
                >
                  By submitting this form and signing up for texts, you consent
                  to receive text messages from Docupdate at the number
                  provided, including messages sent by autodialer. Consent is
                  not a condition of purchase. Msg &amp; data rates may apply.
                  Msg frequency varies. Unsubscribe at any time by replying STOP
                  or clicking the unsubscribe link (where available).
                </p>
              </div>
              {showError && !hasUserConsent && (
                  <p style={{ color: 'red', fontSize: 11 }}>
                    Mark the above checkbox to continue.
                  </p>
              )}
              <div
                style={{
                  color: 'white',
                  fontSize: 12,
                  display: 'flex',
                  alignItems: 'start',
                  marginTop: 10,
                }}
              >
                <Checkbox
                  style={{ marginBottom: 0, marginTop: 2 }}
                  defaultChecked={hasUserAgreedToPolicy}
                  onChange={(val) => setHasUserAgreedToPolicy(val.target.checked)}
                />
                <p
                  style={{
                    alignItems: 'flex-start',
                    margin: '0px 0px 0px 10px',
                  }}
                >
                  I have read and understand the Docupdate
                  <a className="link" href="http://docupdate.io/ccpa" target="_blank" rel="noopener noreferrer">
                    CCPA Policy
                  </a>,
                  <a className="link" href="https://www.docupdate.io/tos" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                  </a>,{' '} and
                  <a className="link" href="https://www.docupdate.io/privacy-policy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                  .{' '}
                </p>
              </div>
              {showError && !hasUserAgreedToPolicy && (
                  <p style={{ color: 'red', fontSize: 11 }}>
                    Mark the above checkbox to continue.
                  </p>
              )}
              <Button
                label="Sign Up"
                type="submit"
                isLoading={loading}
                className="mt-4 px-4 w-100 d-block login-button"
                variant={'default'}
                handleClick={onSubmit}
              />
              <p style={{ color: 'white', textAlign: 'center' }}>
                Already have an account?
                <Link to={'/login-new'}>
                  <span className="highlighted-text">Login</span>
                </Link>
              </p>
            </div>
          </div>
        ) : (
          <div className="form-wrapper">
            <div className="right">
              <div
                style={{
                  padding: 30,
                  backgroundColor: '#7F5CE1',
                  borderRadius: 20,
                  maxWidth: 450,
                  margin: '20px 0px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <img
                    src={docUpdateImage}
                    alt="docupdate"
                    width={235}
                    height={80}
                  />
                </div>
               <TextBox
                 defaultValue={formValues.firstName}
                 placeholder="First Name"
                 onChange={(value) => onTextBoxChange('firstName', value)}
                 error={showError && formValues.firstName.length === 0}
                 errorDescription="First Name is required field."
               />
               <TextBox
                 defaultValue={formValues.lastName}
                 placeholder="Last Name"
                 onChange={(value) => onTextBoxChange('lastName', value)}
                 error={showError && formValues.lastName.length === 0}
                 errorDescription="Last Name is required field."
               />
                <TextBox
                  defaultValue={formValues.email}
                  error={showError && !validateEmail(formValues.email)}
                  errorDescription={
                    formValues.email === ''
                      ? 'Email is  required field.'
                      : 'Invalid Email.'
                  }
                  placeholder="Email"
                  onChange={(value) => onTextBoxChange('email', value)}
                />
                {repeatingEmail && (
                  <p style={{ color: 'red', maxWidth: 450, textAlign: 'left' }}>
                    A user with the given email already exists.
                  </p>
                )}
                <TextBox
                  style={{ marginBottom: '25px' }}
                  defaultValue={formValues.npiNumber?.toString()}
                  error={
                    showError &&
                    !(
                      formValues.npiNumber &&
                      validateNPI(formValues.npiNumber?.toString())
                    )
                  }
                  errorDescription="NPI must be 10 digit long and start with 1 or 2 and not contain any letters or special characters."
                  placeholder="NPI Number"
                  maxLength={10}
                  onChange={(value) => onTextBoxChange('npiNumber', value)}
                />
                <PhoneInput
                  country={'us'}
                  value={formValues.phoneNumber}
                  onChange={(value) => onTextBoxChange('phoneNumber', value)}
                />
                <PasswordInput
                  style={{ marginBottom: '15px' }}
                  error={showError && !validatePassword(formValues.password)}
                  errorDescription="Password must be 8 letter with one digit, one special character, one capital letter, one small letter."
                  placeholder="Password"
                  onChange={(value) => onTextBoxChange('password', value)}
                />
                <div
                  style={{
                    color: 'white',
                    fontSize: 12,
                    display: 'flex',
                    alignItems: 'start',
                  }}
                >
                  <Checkbox
                    style={{ marginBottom: 0, marginTop: 2 }}
                    defaultChecked={hasUserConsent}
                    onChange={(val) => setHasUserConsent(val.target.checked)}
                  />
                  <p
                    style={{
                      alignItems: 'flex-start',
                      width: 510,
                      margin: `0px 0px 5px 10px`,
                    }}
                  >
                    By submitting this form and signing up for texts, you
                    consent to receive text messages from Docupdate at the
                    number provided, including messages sent by autodialer.
                    Consent is not a condition of purchase. Msg &amp; data rates
                    may apply. Msg frequency varies. Unsubscribe at any time by
                    replying STOP or clicking the unsubscribe link (where
                    available).
                  </p>
                </div>
                {showError && !hasUserConsent && (
                  <p style={{ color: 'red', fontSize: 11 }}>
                    Mark the above checkbox to continue.
                  </p>
                  )}
                <div
                  style={{
                    color: 'white',
                    fontSize: 12,
                    display: 'flex',
                    alignItems: 'start',
                    marginTop: 10,
                  }}
                >
                  <Checkbox
                    style={{ marginBottom: 0, marginTop: 2 }}
                    defaultChecked={hasUserAgreedToPolicy}
                    onChange={(val) => setHasUserAgreedToPolicy(val.target.checked)}
                  />
                  <p
                    style={{
                      alignItems: 'flex-start',
                      width: 510,
                      margin: `0px 0px 5px 10px`,
                    }}
                  >
                  I have read and understand the Docupdate
                  <a className="link" href="http://docupdate.io/ccpa" target="_blank" rel="noopener noreferrer">
                    CCPA Policy
                  </a>,
                  <a className="link" href="https://www.docupdate.io/tos" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                  </a>,{' '} and
                  <a className="link" href="https://www.docupdate.io/privacy-policy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                  .{' '}
                  </p>
                </div>
                {showError && !hasUserAgreedToPolicy && (
                  <p style={{ color: 'red', fontSize: 11 }}>
                    Mark the above checkbox to continue.
                  </p>
                )}
                <Button
                  label="Sign Up"
                  type="submit"
                  isLoading={loading}
                  className="mt-4 px-4 w-100 d-block login-button"
                  variant={'default'}
                  handleClick={onSubmit}
                />
                <p style={{ color: 'white', textAlign: 'center' }}>
                  Already have an account?
                  <span
                    className="highlighted-text"
                    onClick={() => navigate('/login-new')}
                  >
                    Login
                  </span>
                </p>
              </div>
              {openVerificationForm && (
                <VerifyOTP
                  userName={formValues.email}
                  password={formValues.password}
                  phoneNumber={formValues.phoneNumber}
                  onClose={() => {
                    setOpenVerificationForm(false);
                  }}
                />
              )}
            </div>
          </div>
        )}
        {}
      </div>
      {errorMessage && (
        <Prompt
          close={() => setErrorMessage(undefined)}
          message={
            <span
              dangerouslySetInnerHTML={{ __html: errorMessage?.message }}
            ></span>
          }
          type={errorMessage?.type}
          promptTitle={errorMessage?.type === 'warning' ? 'Warning' : 'Success'}
        />
      )}
    </div>
  );
};

export default SignUp;

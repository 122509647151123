import CryptoJS from 'crypto-js';

export const useEncryption = () => {
  const secretKey = 'c36b4a199a35eb3cfbebebcc6190ae08';

  const encryptData = (data: string) =>
    CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();

  const decryptData = (cipherText: string) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  return { encryptData, decryptData };
};

import React from 'react';
import LogoNotFound from '../../assets/404.svg'
import { Image } from 'antd';
import './404.scss'

const NotFound = () => {
  return (<div className='heading-h1' style={{marginBottom: 100}}>
    <Image
        className='logo-style'
        preview={false}
        width={150}
        src={LogoNotFound}
    />
    <h1 className='heading-h1'>Page Not Found</h1>
    <p className='desc'>The page you are looking for doesn't exist or has been moved</p>
    <a href="/" className="button-purple">Return to Home</a>
  </div>);
}

export default NotFound;
import React from 'react';

interface ProcessBoxProps {
  number: string;
  text: string;
  color: string;
}

export const ProcessDiv = ({ isMobile }: { isMobile: boolean }) => {
  return (
    <div id="process-space" style={{ position: 'relative', width: '100%' }}>
      <div
        style={{
          width: '100%',
          height: '212px',
          backgroundColor: '#f5f2fd',
          position: 'absolute',
          top: '25%',
        }}
      ></div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
        }}
      >
        <ProcessBox number="1" text="Sign Up" color="#e68989" />
        <ProcessBox number="2" text="Prescribe" color="#ffc170" />
      </div>
    </div>
  );
};

export const ProcessBox: React.FC<ProcessBoxProps> = ({
  number,
  text,
  color,
}) => {
  return (
    <div
      className="custom-block"
      style={{
        boxShadow: `5px 5px 0px 0px ${color}`,
        backgroundColor: '#f8f8fa',
        width: '200px',
        height: '170px',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div
        className="number"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div
          style={{
            padding: '3px',
            border: '4px black solid',
            borderRadius: '5px',
            width: '45px',
            height: '45px',
          }}
        >
          {number}
        </div>
      </div>
      <div className="label">{text}</div>
    </div>
  );
};

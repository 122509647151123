// @flow
import React, { useState } from 'react';
import {
  EyeInvisibleTwoTone,
  EyeTwoTone,
  setTwoToneColor,
} from '@ant-design/icons';
import './passwordInput.scss';

type Props = {
  className?: string;
  value?: string;
  label?: string;
  note?: React.ReactNode;
  onChange: (val?: any) => void;
  inputStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  error?: boolean;
  errorDescription?: string;
  maxLength?: number;
  placeholder?: string;
  onEnter?: () => void;
};

setTwoToneColor('#7e5ae2');

export default function PasswordInput({
  className = '',
  style,
  value,
  label,
  note,
  onChange,
  placeholder,
  inputStyle,
  error,
  errorDescription,
  maxLength,
  onEnter,
}: Props) {
  const [iconVisible, setIconVisible] = useState(false);

  const handleKeyPress = (event: any) => {
    if (event.keyCode === 13) {
      if (onEnter) {
        onEnter();
      }
    }
  };

  const togglePasswordVisibility = () => {
    setIconVisible((prev) => !prev);
  };

  return (
    <div className={`text-box ${className}`} style={style}>
      {label !== undefined ? <p className="text-box-label">{label}</p> : <></>}{' '}
      <div className="row-flex">
        <input
          className="mt-4 input-style"
          id="passwordFiled"
          placeholder={placeholder}
          style={{ fontWeight: 400, color: '#7e7575' }}
          type={!iconVisible ? 'password' : 'text'}
          required={true}
          onChange={onChange}
          onKeyDown={handleKeyPress}
          name="password"
        />
        {iconVisible ? (
          <EyeInvisibleTwoTone onClick={togglePasswordVisibility} />
        ) : (
          <EyeTwoTone onClick={togglePasswordVisibility} />
        )}
      </div>
      <span style={{ color: 'red', fontSize: '12px', display: 'flex' }}>
        {error &&
          (value !== ''
            ? `${
                errorDescription !== ''
                  ? errorDescription
                  : `${label} is invalid.`
              }`
            : `${label} is a required field.`)}
      </span>
      {note}
    </div>
  );
}

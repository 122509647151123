import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import SignIn from '../Pages/Signin/signin';
import SignUp from '../Pages/Signup/signup';
import ForgotPassword from '../Pages/ForgotPassword/forgotPassword';
import AdvisoryPortal from '../Pages/AdvisoryBoardPortal/AdvisoryBoardPortal';
import OTPVerificationComponent from '../Pages/VerifyOTP/verifyOTP';
// import TermsOfService from '../Pages/TOS/tos';
// import PrivacyPolicy from '../Pages/PrivacyPolicy/privacyPolicy';
import DNC from '../Pages/dnc/dnc';
import AdvisoryBoard from '../Pages/AdvisoryBoard/AdvisoryBoard';
import NotFound from '../Pages/404_Not_Found/404';
import EPrescirbe from '../Pages/EPrescribe/EPrescribe';
import backgroundImage from '../assets/background.jpg';
import { useEffect } from 'react';

interface RoutesListProps {
  setBackgroundImage: (background: any) => void;
}

export const authRoutes = [
  '/',
  '/sign-up-new',
  '/login-new',
  '/forgot-password',
];



export const RoutesList: React.FC<RoutesListProps> = ({
  setBackgroundImage,
}) => {
  const location = useLocation();
  const isAuthenticated: boolean = localStorage.getItem('id_token') !== null;

  useEffect(() => {
    if (authRoutes.includes(location.pathname)) {
      setBackgroundImage({ backgroundImage: `url(${backgroundImage})` });
      return;
    }
    setBackgroundImage({ background: 'white' });
  }, [location.pathname, setBackgroundImage]);
  return (
    <Routes key={location.pathname} location={location}>
      <Route
        path="/"
        key="/"
        element={
          isAuthenticated ? (
            <Navigate to="/advisory-board-portal-new" />
          ) : (
            <SignUp />
          )
        }
      />
      <Route
        path="/advisory-board-portal-new"
        element={
          isAuthenticated ? <AdvisoryPortal /> : <Navigate to="/sign-up-new" />
        }
      />
      <Route
        path="/sign-up-new"
        key="/sign-up-new"
        element={
          isAuthenticated ? (
            <Navigate to="/advisory-board-portal-new" />
          ) : (
            <SignUp />
          )
        }
      />
      <Route path="/terms-of-service-new" element={<ExternalRedirect to="https://www.docupdate.io/tos" />} />
      <Route path="/privacy-policy-new" element={<ExternalRedirect to="https://www.docupdate.io/privacy-policy" />} />
      <Route path="/dnc-policy-new" element={<DNC />} />
      <Route
        path="/login-new"
        element={
          isAuthenticated ? (
            <Navigate to="/advisory-board-portal-new" />
          ) : (
            <SignIn />
          )
        }
      />
      <Route
        path="/forgot-password"
        element={
          isAuthenticated ? (
            <Navigate to="/advisory-board-portal-new" />
          ) : (
            <ForgotPassword />
          )
        }
      />
      <Route
        path="/advisory-board-new"
        element={
          isAuthenticated ? <AdvisoryBoard /> : <Navigate to="/sign-up" />
        }
      />
      <Route path="/verify-otp" element={<OTPVerificationComponent />} />
      <Route path="/ePrescribe" element={<EPrescirbe />} />
      <Route path="/not-found" key="/not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/not-found" />} />
    </Routes>
  );
};

function useExternalRedirect(url: string) {
  useEffect(() => {
    window.location.href = url;
  }, [url]);
}

const ExternalRedirect = ({ to }: { to: string }) => {
  useExternalRedirect(to);
  return null;
};
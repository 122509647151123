import { useEffect, useState } from 'react';
import { HashRouter } from 'react-router-dom';
import SideNav from './components/SideNav/sideNav';
import { Layout } from 'antd';
import AppHeader from './components/AdvisoryHeader/AdvisoryHeader';
import { RoutesList } from './components/routes';
import { ScrollToTop } from './components/ScrollToTop';
import Footer from './components/Footer/footer';
import Scrollbars from 'react-custom-scrollbars-2';

const App = () => {
  const isAuthenticated: boolean = localStorage.getItem('id_token') !== null;
  const [background, setBackgroundImage] = useState<any>({ background: 'white' });

  useEffect(() => {}, [isAuthenticated]);
  return (
    <Layout style={{ maxHeight: '100svh', overflowY: 'hidden' }}>
      <HashRouter basename="/">
        {isAuthenticated && <AppHeader />}
        <Layout style={{ background: 'white' }}>
          {isAuthenticated && window.location.pathname === '/' && <SideNav />}
          <Layout
            className="inner-layout"
            style={{
              padding: '0 0',
              overflowX: 'hidden',
              height: 'calc(100svh - 64px)',
              position: 'relative',
              ...background,
            }}
          >
            <Scrollbars>
              <ScrollToTop />
              <RoutesList setBackgroundImage={setBackgroundImage} />
            </Scrollbars>
          </Layout>
        </Layout>
        {!isAuthenticated && <Footer />}
      </HashRouter>
    </Layout>
  );
};

export default App;